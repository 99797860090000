import { Document, Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import Emblem from '../app/assets/img/damasEmblem.jpg';

const styles = StyleSheet.create({
    page: { padding: 10, position: 'relative' },
    image: { 
            width: '10%', 
            height: '10%',
            position: 'absolute',
            top: 10,
            left: 20 
        },
    section: { marginBottom: 10 },
    label: { fontWeight: 'bold' },
    textH1: {
            fontFamily:'Courier',
            fontSize:'1.3rem',
            textAlign: 'center'
        },
    textH2: {
        fontFamily:'Courier',
        fontSize:'1rem',
        textAlign: 'center'
    },
    text: {
        fontFamily:'Courier',
        fontSize:'0.8rem',
        top: 10,
        left: 10
    },
    textVerticalSpacing: {
        top: 5,
        marginBottom: 15
    },
    marginLine: {
        position: 'relative',
        fontWeight: 'ultrabold',
        marginTop: -12,
        textAlign: 'center'
    },
    blankLine: {
        textDecoration: 'underline'
    },
    fieldSpace: {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    flexRight: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}); 

const CampRegistrationPDF = ({ formData }) => {
    return(
        <Document title='Camp Application' author='ADMS'>
            <Page size='LETTER' style={styles.page}>
                <Image src={Emblem} style={ styles.image} />
                <View style={styles.section}>
                    <Text style={ styles.textH1 }>Asociacíon de Damas</Text>
                    <Text style={ styles.textH2 }>Mensajeras del Señor</Text>
                    <Text style={ styles.textH2 }>Campamento General</Text>
                    <Text style={ styles.marginLine }>______________________________________________</Text>
                </View>
                <View style={ [styles.textVerticalSpacing, styles.fieldSpace, styles.flexRight] }>
                    <Text style={ styles.text }>
                        Date: <Text style={styles.blankLine}> {formData.fecha} </Text>
                    </Text>
                </View>
                <View style={ [styles.textVerticalSpacing, styles.fieldSpace] }>
                    <Text style={ styles.text }>
                        Nombre: <Text style={styles.blankLine}> {formData.firstname} </Text>
                    </Text>
                    <Text style={ styles.text }>
                        Apellido: <Text style={styles.blankLine}> {formData.lastname} </Text>
                    </Text>
                    <Text style={ styles.text }>
                        Edad: <Text style={styles.blankLine}> {formData.age} </Text>
                    </Text>
                </View>
                <View style={ [styles.textVerticalSpacing, styles.fieldSpace] }>
                    <Text style={ styles.text }>
                        Marital Status: <Text style={styles.blankLine}> {formData.maritalStatus} </Text>
                    </Text>  
                    <Text style={ [styles.text] }>
                        Email: <Text style={styles.blankLine}> {formData.email} </Text>
                    </Text>
                </View>
                <View style={ [styles.textVerticalSpacing, styles.fieldSpace] }>
                    <Text style={ [styles.text] }>
                        Address: <Text style={styles.blankLine}> {formData.address} </Text>
                    </Text>
                    <Text style={ [styles.text] }>
                        City: <Text style={styles.blankLine}> {formData.city} </Text>
                    </Text>
                    <Text style={ [styles.text] }>
                        State: <Text style={styles.blankLine}> {formData.state} </Text>
                    </Text>
                </View>
                <View style={ [styles.textVerticalSpacing, styles.fieldSpace] }>
                    <Text style={ [styles.text] }>
                        Zip Code: <Text style={styles.blankLine}> {formData.zipcode} </Text>
                    </Text>
                    <Text style={ [styles.text] }>
                        Phone: <Text style={styles.blankLine}> {formData.phone} </Text>
                    </Text>
                    <Text style={ [styles.text] }>
                        Physical Condition: <Text style={styles.blankLine}> {formData.physicalCondition} </Text>
                    </Text>
                </View>
            </Page>
        </Document>
)};

export default CampRegistrationPDF;