import { Nav, NavItem, Navbar, NavbarBrand, Collapse, NavbarToggler, Button } from 'reactstrap';
import { isAuthenticated, userLogout, validateLogin } from '../features/users/userSlice';
import UserLoginForm from '../features/users/UserLoginForm';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../app/assets/logo/whiteEmblem.svg';
import UserAvatar from '../features/users/UserAvatar';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';    

const Navigator = () => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const auth = useSelector(isAuthenticated);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(validateLogin());
    }, [dispatch]);

    const toggleMenu = (index) => {
        setActiveMenu(activeMenu === index ? null : index);
    };  

    const toggleLoginModal = () => {
        setLoginModalOpen(!loginModalOpen);
    };

    const userOptions = auth ? (
        <>  
            <UserAvatar />
            <Button className='nav-link' onClick={() => dispatch(userLogout())}>
                <i className='fa fa-sign-out fa-lg' /> Logout
            </Button>
        </>
    ) : (
            <>
                <NavItem>
                    <Button onClick={toggleLoginModal} className='nav-link'>
                        <i className="fa fa-sign-in fa-lg" /> Login
                    </Button>
                </NavItem>
            </>
    );

    return(
        <>
            <Navbar dark sticky='top' expand='md'>
                <NavbarBrand className='ms-5' tag={NavLink} to='/'>
                    <img src={Logo} alt='Damas Logo' className='float-start' style={{ color: '#FFFFFF'}}/>
                    <h1>ADMS</h1>
                </NavbarBrand>
                <NavbarToggler onClick={() => setMenuOpen(!menuOpen)} />
                <Collapse isOpen={menuOpen} navbar>
                    <Nav className='ms-auto' navbar style={{ display:'flex', alignItems: 'center' }}>
                        <NavItem> 
                            <NavLink className='nav-link' to='/'>
                                <i className='fa fa-home fa-lg' /> Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <div 
                                className='nav-link menu-item'
                                onMouseEnter={() => toggleMenu(0)}
                                onMouseLeave={() => toggleMenu(null)}>
                            <NavLink className='nav-link' to='/directory' >
                                <i className='fa fa-list fa-lg' /> Directory
                            </NavLink>
                                    
                                {
                                    activeMenu === 0 && (
                                        <div className='dropdown-content'>
                                            <ul className='list-unstyled'>
                                                <NavItem>
                                                    <NavLink 
                                                            className='nav-link'
                                                            to='/directory/convention'>
                                                                Convención
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink 
                                                            className='nav-link'
                                                            to='/directory/camp'>
                                                                Campamento
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink 
                                                            className='nav-link'
                                                            to='/directory/regions'>
                                                                Regiones
                                                    </NavLink>
                                                </NavItem>
                                                <hr />
                                                <NavItem>
                                                    <NavLink 
                                                            className='nav-link'
                                                            to='/directory/market'>
                                                                Mercadería
                                                    </NavLink>
                                                </NavItem>
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>
                        </NavItem>
                        <NavItem>
                            <NavLink className='nav-link' to='/aboutus' >
                                <i className='fa fa-info fa-lg' /> About Us
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='nav-link' to='/contactus' >
                                <i className='fa fa-address-card fa-lg' /> Contact Us
                            </NavLink>
                        </NavItem>
                        {userOptions}
                    </Nav>
                </Collapse>
            </Navbar>
            <UserLoginForm isOpen={loginModalOpen} toggle={toggleLoginModal} />
        </>
    )
};

export default Navigator;